@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";


:root{
  --kt-primary: #ff975c;
  --kt-primary-active: #ff7d32;
  --primary: #ff975c;
  --theme-secondary: #1c2138;
  --trans: rgba(0,0,0,0);

}


$my-custom-typography-config: mat.define-typography-config(
  $headline:  mat.define-typography-level(26px, 29.4px, 700, 'Poppins', -0.2px),
  $title: mat.define-typography-level(22px, 24.4px, 700, 'Poppins', -0.2px),
  $subheading-2:  mat.define-typography-level(20px, 22.4px, 600, 'Poppins'),
  $subheading-1:  mat.define-typography-level(18px, 19.4px, 600, 'Poppins'),
  $body-1:  mat.define-typography-level(16px, 22.4px, 400, 'Poppins', 0),
);

body { margin: 0; font-family: 'Poppins', "Helvetica Neue", sans-serif; }


.dashboard-section{

  margin-top: 45px;

  .dashboard-section-header{
    display: flex;
    margin-bottom:25px;
    align-items: center;

    &.large-gap{
      margin-bottom: 25px;
    }

    &.small-gap{
      margin-bottom: 10px;
    }

    h2{
      font-weight: 700;
      font-size: 22px;
      margin: 0;
    }
  }

  .dashboard-card{
    display: flex;
    padding: 25px;
    background-color: #f5f5f5;
    border-radius: 18px;
    height: 100%;

    &.large-card{
      padding: 40px;
    }

    .icon-stat{
      width: 75px !important;
      height: 75px;
      margin-right: 18px;
      border-radius: 18px;
      background-color: var(--theme-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      i{
        color: white;
        font-size: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .card-data{
      flex:1;

      h5.stat-title{
        font-weight: 600;
        font-size: 18px;
        margin:0;
        color: var(--theme-secondary);
      }

      p.stat-description{
        font-weight: 500;
        font-size: 14px;
        margin: 4px 0 8px 0;
        color: #8A8FA9;
      }

      h2{
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        color: var(--theme-secondary);
      }

    }

    &.dashboard-danger{
      .icon-stat{
        background-color: var(--danger);
      }

      .stat-title, h2{
        color: var(--danger);
      }
    }

    &.dashboard-secondary{
      background-color: #383a53 !important;
      .icon-stat{
      }

      .stat-title, h2{
        color: var(--light) !important;
      }
    }

  }

}

.tabset{
  *{
    outline:none;
  }
}

.tabset{

  display: block !important;
  margin: 0 0 1.5rem 0 !important;
  overflow-x: scroll;
  white-space: nowrap;
}

.single-tab{
  display: inline-block;
  margin-right: 20px;
  flex: 0 1 auto;
  cursor: pointer;
  font-weight: 600;
  color: var(--theme-secondary);
  position: relative;
  padding: 10px 23px;
  background-color: white;
  border-radius: 18px;
  border: 3px solid var(--bs-secondary);

  &.selected{
    border: 3px solid var(--primary);

  }


  &:last-child{
    margin-right:0;
  }

  h4{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    font-family: var(--bs-body-font-family);
  }
}

.tab-content{

  padding: 30px;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

}


.empty-progress-bar{

  width: 100%;
  background-color: var(--theme-secondary);
  border-radius: 8px;
  margin: 8px 0 11px 0;

  .progress{
    background: var(--primary);
    border-radius: 8px;
  }

}

